export default (el, propertyName) => {
  return new Promise(resolve => {
    el.addEventListener('transitionend', function handleTransitionEnd(e) {
      if (e.propertyName === propertyName) {
        if (e.target.isSameNode(this)) {
          el.removeEventListener('transitionend', handleTransitionEnd);
          resolve();
        }
      }
    });
  });
};
