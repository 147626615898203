import '../scss/app.scss'; // Vite requires css import in app.js

import './1-modules/lazyload.js';
import './1-modules/nav.js';
import { $ } from './2-helpers/query-selector.js';

function init() {
  // Load less important modules async
  if ($('.js-video-embed')) import('./1-modules/video-embed.js');
  if ($('.js-scroll-top-link')) import('./1-modules/scroll-top-link.js');
  if ($('.js-glide')) import('./1-modules/glide.js');
  if ($('.js-category-filter')) import('./1-modules/category-filter.js');
  if ($('.js-form')) import('./1-modules/form.js');
  if ($('.js-members-index')) import('./1-modules/members-index.js');
  if ($('.js-file-input')) import('./1-modules/file-input.js');
  if ($('.js-article-nav')) import('./1-modules/article-nav.js');
  if ($('.js-teaser-slideshow')) import('./1-modules/teaser-slideshow.js');
  if ($('.js-scroll-to-element')) import('./1-modules/scroll-to-element.js');
}

init();
