export default (callback, options) => {
  options = Object.assign(
    {
      horizontalOnly: false
    },
    options
  );

  const delay = 250;
  let lastResize = 0;
  let windowWidth = window.innerWidth;

  function resize() {
    const now = new Date().getTime();
    if (now - lastResize < delay) return;

    // Ignore iOS UI anim resize event on scroll
    if (options.horizontalOnly) {
      if (window.innerWidth === windowWidth) return;
      windowWidth = window.innerWidth;
    }

    lastResize = now;
    callback();
  }

  window.addEventListener('resize', resize);
  window.addEventListener('orientationchange', resize);
};
