import onResize from '../2-helpers/on-resize';
import transitionEnd from '../2-helpers/transition-end';
import { $, $$ } from '../2-helpers/query-selector';

const dropdowns = $$('.js-dropdown');
const dropdownActiveClass = 'c-nav__item--dropdown-active';
const dropdownHoverClass = 'c-nav__item--dropdown-hover';
const header = $('.js-header-bg');
const headerBgClass = 'l-header__bg--active';
const headerAnimClass = 'l-header__bg--anim';
const largeViewport = window.matchMedia(`(min-width: ${960 / 16}em)`);

function showDropdown(dropdown, hover = false) {
  if (largeViewport.matches) hideAllDropdowns();

  const toggle = $('.js-dropdown-toggle', dropdown);
  toggle.setAttribute('aria-expanded', 'true');
  dropdown.classList.add(dropdownActiveClass);

  if (hover) dropdown.classList.add(dropdownHoverClass);

  header.classList.add(headerBgClass);
  header.classList.add(headerAnimClass);
}

async function hideDropdown(dropdown, hover = false) {
  const toggle = $('.js-dropdown-toggle', dropdown);
  toggle.setAttribute('aria-expanded', 'false');
  dropdown.classList.remove(dropdownActiveClass);
  dropdown.classList.remove(dropdownHoverClass);

  if (header.classList.contains(headerBgClass)) {
    header.classList.remove(headerBgClass);
    await transitionEnd(header, 'opacity');
    header.classList.remove(headerAnimClass);
  }
}

export function hideAllDropdowns() {
  for (const dropdown of dropdowns) {
    if (dropdown.classList.contains(dropdownActiveClass))
      hideDropdown(dropdown);
  }
}

export function initDropdowns() {
  for (const dropdown of dropdowns) {
    const toggle = $('.js-dropdown-toggle', dropdown);
    const list = $('.js-dropdown-list', dropdown);

    toggle.addEventListener('click', () => {
      if (dropdown.classList.contains(dropdownHoverClass)) return;

      dropdown.classList.contains(dropdownActiveClass)
        ? hideDropdown(dropdown)
        : showDropdown(dropdown);
    });

    dropdown.addEventListener('mouseenter', () => {
      if (
        !dropdown.classList.contains(dropdownActiveClass) &&
        largeViewport.matches
      ) {
        showDropdown(dropdown, true);
      }
    });

    dropdown.addEventListener('mouseleave', () => {
      if (
        dropdown.classList.contains(dropdownActiveClass) &&
        largeViewport.matches
      ) {
        hideDropdown(dropdown, true);
      }
    });
  }

  // Close dropdown on resize
  onResize(
    () => {
      hideAllDropdowns();
    },
    { horizontalOnly: true }
  );

  // Close dropdowns on main click
  $('.js-main').addEventListener('click', () => {
    hideAllDropdowns();
  });

  // Calc header bg size
  let maxHeight = 250 + 32;

  for (const dropdown of dropdowns) {
    const list = $('.js-dropdown-list', dropdown);

    maxHeight = Math.max(maxHeight, list.offsetHeight);
  }

  header.style.setProperty('--header-bg-height', maxHeight - 32 + 'px');
}
